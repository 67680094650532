import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./utils/axios";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "context/AuthProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CssBaseline } from "@mui/material";
import UserPreferencesProvider from "context/UserPreferencesProvider";
import DrawerProvider from "context/DrawerProvider";
import AboutProvider from "context/AboutProvider";
import ThemeProvider from "context/ThemeProvider";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import NavProvider from "context/NavProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AuthProvider>
              <UserPreferencesProvider>
                <ThemeProvider>
                  <DrawerProvider>
                    <CssBaseline />
                    <AboutProvider>
                      <App />
                    </AboutProvider>
                  </DrawerProvider>
                </ThemeProvider>
              </UserPreferencesProvider>
            </AuthProvider>
          </LocalizationProvider>
        </QueryClientProvider>
      </QueryParamProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
