import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginMain from "pages/login/main";
import HomeMain from "pages/home/main";
import ProtectedRoute from "components/ProtectedRoute";
import AnonymRoute from "components/AnonymRoute";
import Devices from "pages/devices";
import ROUTES from "routes";
import AboutDialog from "components/AboutDialog";
import MyProfile from "pages/myProfile";
import Map from "pages/map";
import Charts from "pages/charts";
import DevicesConfig from "pages/devicesConfig";
import { useAuth } from "context/AuthProvider";
import Firmwares from "pages/firmwares";
import SmappManagement from "pages/management";

const App: React.FC = () => {
  const { tokens, isAdmin } = useAuth();
  if (tokens.access === undefined) {
    return (
      <Routes>
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <HomeMain />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.LOGIN}
          element={
            <AnonymRoute>
              <LoginMain />
            </AnonymRoute>
          }
        />
      </Routes>
    );
  }

  return (
    <>
      <Routes>
        <Route
          path={ROUTES.LOGIN}
          element={
            <AnonymRoute>
              <LoginMain />
            </AnonymRoute>
          }
        />
        <Route
          path={`${ROUTES.DEVICES}/*`}
          element={
            <ProtectedRoute>
              <Devices />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${ROUTES.MAP}/*`}
          element={
            <ProtectedRoute>
              <Map />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${ROUTES.CHARTS}/*`}
          element={
            <ProtectedRoute>
              <Charts />
            </ProtectedRoute>
          }
        />
        {isAdmin && (
          <Route
            path={`${ROUTES.DEVICES_CONFIG}/*`}
            element={
              <ProtectedRoute>
                <DevicesConfig />
              </ProtectedRoute>
            }
          />
        )}
        {isAdmin && (
          <Route
            path={`${ROUTES.FIRMWARES}/*`}
            element={
              <ProtectedRoute>
                <Firmwares />
              </ProtectedRoute>
            }
          />
        )}
        {isAdmin && (
          <Route
            path={`${ROUTES.MANAGEMENT}/*`}
            element={
              <ProtectedRoute>
                <SmappManagement />
              </ProtectedRoute>
            }
          />
        )}
        <Route
          path={ROUTES.HOME}
          element={
            <ProtectedRoute>
              <HomeMain />
            </ProtectedRoute>
          }
        />
        <Route
          path={ROUTES.MY_PROFILE}
          element={
            <ProtectedRoute>
              <MyProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <HomeMain />
            </ProtectedRoute>
          }
        />
      </Routes>
      <AboutDialog />
    </>
  );
};

export default App;
