import React from "react";
import { Box, ListSubheader, Paper } from "@mui/material";
import DetectionCountDeltaChart, {
  DetectionCountDeltaChartProps,
  DC_CHART_CONFIG,
  DetectionCountDeltaSummedChart,
} from "components/visualization/DetectionCountDeltaChart";
import useDateRange from "hooks/useDateRange";

type DetectionCountDeltaChartPanelProps = Pick<DetectionCountDeltaChartProps, "deviceIdIn">;

export const DetectionCountDeltaSummedChartPanel: React.FC<
  DetectionCountDeltaChartPanelProps & { title?: string; frequency?: string }
> = ({ deviceIdIn, frequency, title }) => {
  const { dateRange, form: dateRangeForm } = useDateRange({ defaultDuration: { days: 30 * 12 } });

  const msg =
    deviceIdIn?.length === 1 ? `Detection count delta ${title}` : "Average detection count delta";

  return (
    <Paper
      elevation={3}
      sx={{
        pt: 3,
        pb: 2,
        px: 2,
        height: "min(400px, calc(100vw / 2))",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ mb: 1, display: "flex" }}>
        <ListSubheader sx={{ flex: 1, fontSize: "17px", fontWeight: "bold" }}>{msg}</ListSubheader>
        {dateRangeForm}
      </Box>
      <Box sx={{ flex: 1 }}>
        <DetectionCountDeltaSummedChart
          frequency={frequency || "day"}
          deviceIdIn={deviceIdIn}
          from={dateRange.from}
          to={dateRange.to}
          chartConfig={[{ ...DC_CHART_CONFIG, name: msg }]}
        />
      </Box>
    </Paper>
  );
};

const DetectionCountDeltaChartPanel: React.FC<DetectionCountDeltaChartPanelProps> = ({
  deviceIdIn,
}) => {
  const { dateRange, form: dateRangeForm } = useDateRange({ defaultDuration: { days: 30 * 12 } });

  const title =
    deviceIdIn?.length === 1 ? "Detection count delta" : "Average detection count delta";

  return (
    <Paper
      elevation={3}
      sx={{
        pt: 3,
        pb: 2,
        px: 2,
        height: "min(400px, calc(100vw / 2))",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ mb: 1, display: "flex" }}>
        <ListSubheader sx={{ flex: 1 }}>{title}</ListSubheader>
        {dateRangeForm}
      </Box>
      <Box sx={{ flex: 1 }}>
        <DetectionCountDeltaChart
          deviceIdIn={deviceIdIn}
          from={dateRange.from}
          to={dateRange.to}
          chartConfig={[{ ...DC_CHART_CONFIG, name: title }]}
        />
      </Box>
    </Paper>
  );
};

export default DetectionCountDeltaChartPanel;
