import axios from "axios";
import CONFIG from "config";
import { API_ROUTES } from "routes";
import set from "lodash/set";
import qs from "qs";
import { refreshToken } from "queries/auth";
import { getAccessToken, getRefreshToken, setTokens } from "./token";

axios.defaults.baseURL = CONFIG.baseUrl;
// axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
// const PUBLIC_PATHS = ["/api/auth/"];

// const isProtectedRoute = (path: string | undefined): boolean => {
//   if (path === undefined) return false;
//   return !PUBLIC_PATHS.includes(path);
// };

axios.interceptors.request.use(
  async (config) => {
    if (config.url === undefined) config.url = "/";
    else if (!config.url.endsWith("/")) config.url = `${config.url}/`;

    // configuring array format
    config.paramsSerializer = {
      serialize: (params) => qs.stringify(params, { arrayFormat: "comma" }),
    };

    if (config.url === API_ROUTES.ACCESS_TOKEN || config.url === API_ROUTES.REFRESH_TOKEN) {
      return config;
    }

    if (!getAccessToken()) {
      const refresh = getRefreshToken();
      if (refresh) {
        await refreshToken({ refresh }).then((res) => setTokens(res));
      }
    }

    const access = getAccessToken();
    if (access) {
      set(config, "headers.Authorization", `Bearer ${access}`);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
