import React from "react";
import { Chip } from "@mui/material";
import EmojiNatureOutlinedIcon from "@mui/icons-material/EmojiNatureOutlined";
import { ListSummationDetectionCountItem } from "queries/devices/averageDetectionCounts";
import { ListResponse } from "queries/types";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "components/Loader";

type SummedType = {
  promise: Promise<ListResponse<ListSummationDetectionCountItem>>;
  key: string;
}

type SummedDetectionCountChipProps = {
  title: string;
  value?: SummedType;
};
const SummedDetectionCountChip: React.FC<SummedDetectionCountChipProps> = ({ title, value }) => {

  const { data, isLoading } = useQuery({
    queryKey: ["list-all-average-measurements", value?.key],
    queryFn: async () => value?.promise,
    enabled: value !== undefined,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

  if (isLoading) {
    return <Chip icon={<EmojiNatureOutlinedIcon />} label={<Loader />} variant="outlined" />;
  }

  const summed = (data?.data.map(v => v.sum_detection_count_delta) ?? []).at(-1)
  return <Chip icon={<EmojiNatureOutlinedIcon />} label={`${summed || 0} ${title}`} variant="outlined" />;
};

export default SummedDetectionCountChip;
