/* eslint-disable import/prefer-default-export */
import axios, { GenericAbortSignal } from "axios";
import { listAllFactory } from "utils/query";
import { ListResponse, Meta } from "../types";

type ListLastGeolocationParams = {
  page?: number;
  page_size?: number;
  active?: boolean;
};
export type ListLastGeolocationItem = {
  id: string;
  smapp_id: string;
  name: string | null;
  detection_count: number;
  detection_count_delta: number;
  geo_location: {
    longitude: number;
    latitude: number;
  };
  east?: {
    lat: number;
    lng: number;
  };
  west?: {
    lat: number;
    lng: number;
  };
};
export const listLastGeolocations = async (
  params?: ListLastGeolocationParams,
  signal?: GenericAbortSignal | undefined
): Promise<ListResponse<ListLastGeolocationItem>> => {
  const { data } = await axios.get<ListResponse<ListLastGeolocationItem>>(
    "/api/devices/last-geo-points/",
    { params, signal }
  );
  return data;
};

export const listAllLastGeolocations = listAllFactory(listLastGeolocations);
// export const listAllLastGeolocations = listAllFactory(listLastGeolocations, { isHuge: true });

// type DetailGeolocationParams = {
//   id: string;
// };
// export type DetailGeolocationItem = ListLastGeolocationItem;
// export const detailGeolocation = async ({
//   id,
// }: DetailGeolocationParams): Promise<DetailGeolocationItem> => {
//   const { data } = await axios.get<DetailGeolocationItem>(`/api/devices/geo-locations/${id}`);
//   return data;
// };
