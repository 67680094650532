import React from "react";
import { SxProps, styled, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import useBackgroundProps from "hooks/useBackgroundProps";
import AppBar from "./AppBar";
import NavigationDrawer from "./NavigationDrawer";
// import PreferencesDrawer from "./Drawers/PreferencesDrawer";

type AppWrapperProps = {
  showAppBar?: boolean;
  mainSxProps?: SxProps;
};

const AppBarHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AppWrapper = React.forwardRef<HTMLDivElement, React.PropsWithChildren<AppWrapperProps>>(
  ({ children, showAppBar = true, mainSxProps = {} }, ref) => {
    const theme = useTheme();

    const backgroundProps = useBackgroundProps();

    return (
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh", ...backgroundProps }}
      >
        {showAppBar && <AppBar />}
        <NavigationDrawer />
        {showAppBar && <AppBarHeader />}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: [2, 2, 3, 3],
            display: "flex",
            flexDirection: "column",
            maxWidth: theme.breakpoints.values.xl,
            alignSelf: [undefined, undefined, undefined, undefined, "center"],
            ...mainSxProps,
          }}
          ref={ref}
        >
          {children}
        </Box>
        {/* <PreferencesDrawer /> */}
      </Box>
    );
  }
);

export default AppWrapper;
