import { Box } from "@mui/material";
import styled from "@emotion/styled";

type StatusIndicatorProps = {
  status: "ok" | "warning" | "error";
};
const StatusIndicator = styled(Box)`
  width: 5px;
  border-radius: 5px;
  background: ${({ status }: StatusIndicatorProps) => (status === "ok" ? "green" : "red")};
`;

export default StatusIndicator;
