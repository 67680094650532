import { Box, Typography } from "@mui/material";
import { FlexLoader } from "components/Loader";
import React from "react";
import Chart, { ChartProps } from "./Chart";

// React Query support for Chart component

type ChartWithRQProps<TKey extends Exclude<string, "x">> = {
  error?: unknown;
} & ChartProps<TKey>;

const ChartWithRQ = <TKey extends string>({ data, error, ...props }: ChartWithRQProps<TKey>) => {
  let content = <FlexLoader />;
  if (error) {
    content = (
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Typography variant="overline">{String(error)}</Typography>
      </Box>
    );
  }
  if (data) {
    content = <Chart data={data} {...props} />;
  }

  return content;
};

export default ChartWithRQ;
