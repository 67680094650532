import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import {
  Alert,
  Box,
  Button,
  ButtonProps,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellParams,
  GridColDef,
} from "@mui/x-data-grid";
import { v1 as uuidv1 } from "uuid";
import { listConfigs } from "queries/configs";
import { ListDeviceItem, listDevices } from "queries/devices";
import { TargetConfigType, patchTargetConfig } from "queries/devices/targetConfig";
import { clearListChars } from "utils/react";
import { ListResponse, Meta } from "queries/types";
import { ButtonWithRequest } from "./Contents/utils/common.fn";
import { CurrentDeviceConfigType, MyFilter, MyFilterList } from "./Contents/utils/types";
import { defaultMyFilter } from "./Contents/utils/defaults";

const PAGE_SIZE = 8;
const selectedAllValue = Object.fromEntries(
  Object.entries(defaultMyFilter).map(([k, v]) => [k, { ...v, value: "*" }])
) as MyFilter;

function toRanges(input: string): string {
  let strings: string[] = input.split(",");
  if (strings.length === 1) return input;

  strings = strings.map((str) => str.trim());
  return `${strings[0]}...${strings[strings.length - 1]}`;
}

const headerButtonProps = {
  variant: "outlined",
  size: "small",
  sx: { width: "auto", minWidth: "0px", minHeight: "0px" },
} as ButtonProps;

const TargetDevicesToApply: React.FC<{
  deviceListState: [ListDeviceItem[], React.Dispatch<React.SetStateAction<ListDeviceItem[]>>];
  columns: GridColDef<any>[];
  setPaginationModel?: { page: number; pageSize: number };
  enabled: boolean;
}> = ({ deviceListState: [deviceList, setDeviceList], columns, setPaginationModel, enabled }) => {
  return (
    <>
      {enabled && deviceList.length !== 0 && (
        <DataGrid
          rows={deviceList}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: setPaginationModel || { page: 0, pageSize: 3 },
            },
          }}
          // pageSizeOptions={[25, 50]}
          onRowSelectionModelChange={(itm) =>
            setDeviceList(deviceList.filter((v) => itm.every((itmid) => itmid !== v.id)))
          }
        />
      )}
    </>
  );
};

const initDeviceList = {
  data: [],
  meta: {} as Meta,
};

const FilteredDeviceList: React.FC<{
  filter: MyFilter;
  currentDeviceConfig: CurrentDeviceConfigType;
}> = ({ filter, currentDeviceConfig }) => {
  const queryClient = useQueryClient();
  const overrides = {
    // https://github.com/mui/mui-x/issues/8168
    //  "& .MuiDataGrid-main": {
    //   boom, what did you expect ?
    //  width: "99.9%"
    //  }
    "& .MuiDataGrid-main": {
      width: 0,
      minWidth: "95%",
    },
  };

  const [openTargetDevices, setOpenTargetDevices] = useState(false);

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: PAGE_SIZE });

  const [targetDevices, setTargetDevices] = React.useState<ListDeviceItem[]>([]);
  const [filterList, setFilterList] = React.useState<MyFilterList[]>([]);
  const [deviceList, setDeviceList] = React.useState<ListResponse<ListDeviceItem>>(initDeviceList);

  const patchTargetConfigMutation = useMutation<
    { devices: number },
    Error,
    TargetConfigType,
    unknown
  >({
    mutationFn: (body: TargetConfigType) => {
      return patchTargetConfig(body);
    },
    onSuccess: () => {
      setSelectedRows([]);
      setTargetDevices([]);
      setFilterList([]);
      setDeviceList(initDeviceList);
    },
  });

  const [rowCountState, setRowCountState] = React.useState(deviceList.meta.count || 0);

  const applyButtonEnabled =
    currentDeviceConfig.name !== "" && (targetDevices.length !== 0 || filterList.length !== 0);

  const createQueryParams = (f: MyFilter) => {
    const params: any = {};
    Object.entries(f).forEach(([k, v]) => {
      if (!v || !v.value || v.value === "*") {
        return;
      }
      const multiChoice = v.value?.includes(",") && v.lookups && v.lookups.includes("in");
      const lookup = multiChoice ? "in" : v.lookups?.filter((v) => v !== "in").shift() || null;
      if (lookup) {
        params[`${v.queryParams}__${lookup}`] = multiChoice
          ? v.value.split(" ").map((v) => clearListChars(v, [" "], true))
          : v.value;
      } else {
        params[`${v.queryParams}`] = v.value;
      }
    });
    return params;
  };

  const deviceListQuery = useQuery<ListResponse<ListDeviceItem>>({
    queryKey: ["list-devices", paginationModel, filter],
    // queryFn: async () => mapFilter(filter, paginationModel),
    queryFn: async () =>
      listDevices({
        page: paginationModel.page + 1,
        page_size: paginationModel.pageSize,
        active: true,
        ...createQueryParams(filter),
      }),
    // staleTime: Infinity,
    // cacheTime: Infinity,
    keepPreviousData: true,
    onSuccess: async (device) => {
      const configs = await listConfigs({
        page: 1,
        page_size: PAGE_SIZE,
        id__in: [...new Set(device.data.map((v) => v.target_config))],
      });
      setDeviceList({
        // remap target_config (which is an UUID) to target_config.name
        data: device.data.map((dev) => {
          const targetConfName = configs.data.find((devC) => devC.id === dev.target_config)?.name;
          if (targetConfName !== undefined) return { ...dev, target_config: targetConfName };
          return dev;
        }),
        meta: device.meta,
      });
    },
  });

  const isAllSelected = useMemo(() => {
    return filterList.filter((v) => v.selectedAll === true).length > 0;
  }, [filterList]);

  useEffect(() => {
    if (!deviceListQuery.isPreviousData && deviceListQuery.data?.meta.next) {
      const configuredPm = { page: paginationModel.page + 1, pageSize: paginationModel.pageSize };
      queryClient.prefetchQuery({
        queryKey: ["list-devices", configuredPm, filter],
        queryFn: async () =>
          listDevices({
            page: configuredPm.page + 1,
            page_size: configuredPm.pageSize,
            ...createQueryParams(filter),
          }),
        staleTime: 0,
        cacheTime: 0,
      });
    }
  }, [queryClient, deviceListQuery, paginationModel, filter]);

  useEffect(() => {
    if (deviceList !== undefined) {
      setRowCountState((prevRowCountState) =>
        deviceList.meta.count !== undefined ? deviceList.meta.count : prevRowCountState
      );
    }
  }, [deviceList, setRowCountState]);

  const handleSelectDevices = useCallback(() => {
    if (deviceList === undefined) return;

    const allRowIds = deviceList.data.map((row) => row.id);
    const isFilterEmpty = Object.values(filter).every((v) => _.isEmpty(v.value));
    const displayToRanges = Object.entries(selectedAllValue).reduce((pre: any, [k, v]) => {
      const toRange = toRanges(filter[k as keyof MyFilter].value || "");
      pre[k] = { value: toRange };
      return pre;
    }, {}) as MyFilter;

    const newFilter = {
      value: isFilterEmpty ? selectedAllValue : filter,
      display: isFilterEmpty ? selectedAllValue : displayToRanges,
    };

    if (allRowIds.length !== 0) {
      if (isFilterEmpty) setFilterList([]);
      setSelectedRows(allRowIds);
      setFilterList((pre) => [...pre, { id: uuidv1(), selectedAll: isFilterEmpty, ...newFilter }]);
    }
  }, [filter, deviceList]);

  const columns = React.useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        width: 150,
        renderHeader: () => (
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Button
              {...headerButtonProps}
              title="Clear all selected element"
              onClick={() => setSelectedRows([])}
            >
              {" "}
              <ClearIcon fontSize="small" />{" "}
            </Button>
            <Button
              {...headerButtonProps}
              title="Add all to target devices"
              onClick={() => handleSelectDevices()}
            >
              {" "}
              <AddIcon fontSize="small" />{" "}
            </Button>
          </Box>
        ),
        renderCell: (params: GridCellParams) => (
          <Checkbox
            checked={selectedRows.includes(params.row.id)}
            onChange={(event) => {
              const rowId = params.row.id;
              const oneDevice = deviceList?.data.find((v) => v.id === rowId);
              if (event.target.checked) {
                if (oneDevice) setTargetDevices((pre) => [...pre, oneDevice]);
                setSelectedRows((pre) => [...pre, rowId]);
              } else {
                setSelectedRows((pre) => pre.filter((id) => id !== rowId));
              }
            }}
          />
        ),
      },
      { field: "smapp_id", headerName: "Smapp ID", flex: 1, minWidth: 100 },
      // { field: "name", headerName: "Name", flex: 1, minWidth: 130 },
      { field: "imei", headerName: "Imei", flex: 1, minWidth: 160 },
      { field: "mac", headerName: "Mac", flex: 1, minWidth: 160 },
      { field: "country", headerName: "Country", flex: 1, minWidth: 80 },
      { field: "type", headerName: "Type", flex: 1, minWidth: 80 },
      { field: "target_config", headerName: "Config Name", flex: 1, minWidth: 130 },
    ],
    [selectedRows, deviceList?.data, handleSelectDevices]
  );

  const ApplyConfigForTargetOrFilteredDevice = useCallback(() => {
    const cleanedUpFilters: Record<string, null | string[]>[] = filterList
      .map((v) => {
        const qp = createQueryParams(v.value);
        return _.isEmpty(qp) ? undefined : qp;
      })
      .filter((v) => v !== undefined);

    const targetConfig = {
      newTargetConfigId: currentDeviceConfig.id,
      device: {
        allDeviceSelected: isAllSelected,
        ids: targetDevices.map((v) => v.id),
        filters: cleanedUpFilters,
      },
    } as TargetConfigType;

    patchTargetConfigMutation.mutate(targetConfig);

    setTimeout(() => {
      deviceListQuery.refetch();
    }, 1000);
  }, [
    currentDeviceConfig.id,
    filterList,
    targetDevices,
    deviceListQuery,
    isAllSelected,
    patchTargetConfigMutation,
  ]);

  const applyButtonTitle = (
    <span>
      <Typography>
        Config name provided
        {(currentDeviceConfig.name || "").trim() !== "" ? (
          <CheckIcon fontSize="small" />
        ) : (
          <ErrorIcon fontSize="small" />
        )}
      </Typography>
      <Typography>
        Target devices/filter provided
        {targetDevices.length !== 0 || filterList.length !== 0 ? (
          <CheckIcon fontSize="small" />
        ) : (
          <ErrorIcon fontSize="small" />
        )}
      </Typography>
    </span>
  );

  const allDeviceHeader = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <Typography sx={{ flex: 1.5 }} variant="body1" gutterBottom>
        All Devices
      </Typography>

      <Tooltip title="Selected template for apply">
        <Typography sx={{ flex: 3, textAlign: "center" }} variant="body1" gutterBottom>
          {currentDeviceConfig.name}
        </Typography>
      </Tooltip>

      <Tooltip title={applyButtonTitle}>
        <span>
          <ButtonWithRequest
            mutation={patchTargetConfigMutation}
            disabled={!applyButtonEnabled}
            mutationCallback={ApplyConfigForTargetOrFilteredDevice}
          >
            Apply Config
          </ButtonWithRequest>
        </span>
      </Tooltip>

      <Tooltip title="Fullscreen">
        <Button onClick={() => setOpenTargetDevices((val) => !val)}>
          <FullscreenIcon />
        </Button>
      </Tooltip>

      <Tooltip title="Clear target devices">
        <Button onClick={() => setTargetDevices([])}>
          <ClearIcon />
        </Button>
      </Tooltip>
    </Box>
  );

  const allDevicesElement = (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        pageSizeOptions={[10, 20, 100]}
        sx={{
          ...overrides,
          width: "97%",
          maxHeight: 750,
        }}
        initialState={{
          pagination: {
            // paginationModel: { page: 0, pageSize: PAGE_SIZE },
            // paginationModel: { page: 0 },
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        columns={columns}
        loading={deviceListQuery.isLoading}
        checkboxSelection
        rowCount={rowCountState}
        rows={deviceList.data}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
      />
    </Box>
  );

  const targetDevicesDataGrid = (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        pageSizeOptions={[10]}
        sx={{
          ...overrides,
          minHeight: 170,
          maxHeight: 170,
          width: "97%",
        }}
        initialState={{
          pagination: {
            // paginationModel: { page: 0, pageSize: 5 },
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        columns={[
          ...columns,
          {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            headerAlign: "right",
            align: "right",
            renderCell: (params) => {
              return (
                <Button
                  sx={{ minWidth: 0 }}
                  onClick={(e) => {
                    setTargetDevices((pre) => pre.filter((v) => v.id !== params.row.id));
                    // setSelectedAllDevices(isAllSelected.or)
                  }}
                  variant="contained"
                >
                  <DeleteIcon />
                </Button>
              );
            },
          },
        ]}
        rows={targetDevices}
      />
    </Box>
  );

  const targetDeviceAsDialog = (
    <Dialog
      open={openTargetDevices}
      PaperProps={{
        style: {
          minHeight: "90%",
          minWidth: "90%",
        },
      }}
    >
      <DialogContent>
        <TargetDevicesToApply
          enabled={openTargetDevices}
          deviceListState={[targetDevices, setTargetDevices]}
          columns={columns}
          setPaginationModel={{ page: 0, pageSize: 15 }}
        />
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={() => setOpenTargetDevices((val) => !val)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const targetFilters = (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        sx={{
          ...overrides,
          minHeight: 170,
          width: "97%",
        }}
        initialState={{
          pagination: {
            // paginationModel: { page: 0, pageSize: 5 },
            paginationModel: { page: 0 },
          },
        }}
        columns={[
          { field: "country", headerName: "Country", flex: 1 },
          { field: "pest", headerName: "Pest", flex: 1 },
          { field: "crop", headerName: "Crop", flex: 1 },
          { field: "smapp_id", headerName: "SmappId", flex: 1 },
          { field: "imei", headerName: "Imei", flex: 1 },
          { field: "mac", headerName: "Mac", flex: 1 },
          { field: "type", headerName: "Type", flex: 1 },
          {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            headerAlign: "right",
            align: "right",
            renderCell: (params) => {
              return (
                <Button
                  sx={{ minWidth: 0 }}
                  onClick={(e) => {
                    setFilterList((pre) => pre.filter((v) => v.id !== params.row.id));
                    // setSelectedAllDevices(isAllSelected.and)
                  }}
                  variant="contained"
                >
                  <DeleteIcon />
                </Button>
              );
            },
          },
        ]}
        rows={filterList.map((v) => {
          return {
            id: v.id,
            ...Object.fromEntries(
              Object.entries(v.display).map(([k, v]) => {
                return [k, v.value];
              })
            ),
          };
        })}
      />
    </Box>
  );

  return (
    <Box sx={{ margin: "1px", padding: "1px" }}>
      <Card sx={{ margin: "5px", padding: "5px" }}>
        {patchTargetConfigMutation.isError && (
          <Alert severity="error">Apply config: {patchTargetConfigMutation.error.message}</Alert>
        )}
        {allDeviceHeader}
        {allDevicesElement}
        {targetDeviceAsDialog}
        Target devices
        {targetDevicesDataGrid}
        Target filters
        {targetFilters}
      </Card>
    </Box>
  );
};

export default FilteredDeviceList;
