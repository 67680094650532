import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UseMutateFunction, useMutation, useQuery } from "@tanstack/react-query";
import { accessToken, AccessTokenParams, TokenResponse } from "queries/auth";
import { DecodedToken, decode, getTokens, removeTokens, setTokens } from "utils/token";
import ROUTES from "routes";
import { ListUserItem, USER_TYPE, detailUser } from "queries/users";
import { AxiosError } from "axios";

type AuthContextProps = {
  currentUser?: ListUserItem;
  login: UseMutateFunction<TokenResponse, unknown, AccessTokenParams, unknown>;
  tokens: { access?: DecodedToken; refresh?: DecodedToken };
  isLoggingIn: boolean;
  logout: () => void;
  isAdmin: boolean;
  AuthError: AxiosError | null;
};

const INITIAL_VALUES: AuthContextProps = {
  currentUser: undefined,
  login: () => {},
  tokens: {},
  isLoggingIn: false,
  logout: () => {},
  isAdmin: false,
  AuthError: null,
};

const AuthContext = React.createContext<AuthContextProps>(INITIAL_VALUES);

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const mutation = useMutation<TokenResponse, AxiosError, AccessTokenParams, unknown>({
    mutationFn: accessToken,
    onSuccess: (res) => {
      const access = decode(res.access);
      if (process.env.REACT_APP_ALLOW_ALL || access.payload?.user.type === USER_TYPE.ADMIN) {
        setTokens(res);

        // enqueue notification
        const from = location.state?.from?.pathname || ROUTES.HOME;
        navigate(from, { replace: true });
      }
    },
    // onError: (e: AxiosError) => {
    //   // eslint-disable-next-line no-console
    //   // console.log("Something went wrong", e, mutation.error?.message);
    //   console.log("Something went wrong", mutation.error);
    // },
  });

  const logout = React.useCallback(() => {
    removeTokens();
    if ("caches" in window) {
      const clearIt = async () => {
        const cacheKeys = await window.caches.keys();
        await Promise.all(
          cacheKeys.map((key) => {
            return window.caches.delete(key);
          })
        );
      };
      clearIt();
    }
    localStorage.clear();
    navigate(ROUTES.LOGIN, { replace: true });
  }, [navigate]);

  const { access, refresh } = getTokens();

  const tokens = React.useMemo(() => {
    const tokens: AuthContextProps["tokens"] = {};

    if (access) tokens.access = decode(access);
    if (refresh) tokens.refresh = decode(refresh);
    return tokens;
  }, [access, refresh]);

  const userId = React.useMemo(
    () => tokens.access?.payload?.user_id,
    [tokens.access?.payload?.user_id]
  );

  const { data: currentUser, error: AuthError } = useQuery<ListUserItem, AxiosError>({
    queryKey: ["detail-user", { id: userId }],
    queryFn: () => detailUser({ id: userId as string }),
    enabled: !!userId,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  React.useEffect(() => {
    if (currentUser && AuthError && AuthError.response?.status === 401) {
      logout();
    }
  }, [currentUser, AuthError, logout]);

  React.useEffect(() => {
    if (!process.env.REACT_APP_ALLOW_ALL && currentUser && currentUser.type !== USER_TYPE.ADMIN) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const value = React.useMemo(
    () => ({
      login: mutation.mutate,
      isLoggingIn: mutation.isLoading,
      logout,
      tokens,
      currentUser,
      isAdmin: tokens.access?.payload?.user.type === USER_TYPE.ADMIN,
      AuthError: mutation.error,
    }),
    [mutation.mutate, mutation.isLoading, mutation.error, logout, tokens, currentUser]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => React.useContext(AuthContext);
