import * as React from "react";
import {
  DataGrid,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, ButtonProps, Checkbox, TextField, Tooltip } from "@mui/material";
import _, { debounce } from "lodash";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ListDeviceItem, ListDeviceParams, listDevices } from "queries/devices";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import exportDevice from "queries/devices/managementExport";

const qKey = "listOfDevices";
const PAGE_SIZE = 5;

const headerButtonProps = {
  variant: "outlined",
  size: "small",
  sx: { width: "auto", minWidth: "0px", minHeight: "0px" },
} as ButtonProps;

const modelParams = (paginationModel: any, search: string) => {
  const params = {
    page: paginationModel.page + 1,
    page_size: paginationModel.pageSize,
    hr: "t",
    active: true,
  } as ListDeviceParams;
  if (search.length > 0) params.search = search;
  return params;
};

const listModel = (paginationModel: any, search: string) => {
  return listDevices(modelParams(paginationModel, search));
};

const preListModel = (paginationModel: any, search: string) => {
  const params = modelParams(paginationModel, search);
  params.page += 1;
  return listDevices(params);
};

type DeviceTableParamsType = {
  setDevices: React.Dispatch<React.SetStateAction<ListDeviceItem[]>>;
};

const initPagination = { page: 0, pageSize: PAGE_SIZE };

const DeviceTable = ({ setDevices }: DeviceTableParamsType) => {
  const queryClient = useQueryClient();
  const [paginationModel, setPaginationModel] = React.useState(initPagination);
  const [search, setSearch] = React.useState("");
  const onNameChange = React.useMemo(
    () =>
      debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setPaginationModel((pre) => ({ ...pre, page: 0 }));
        setSearch(value);
      }, 600),
    []
  );

  const exportMutation = useMutation({
    mutationFn: exportDevice,
  });

  const listQuery = useQuery(
    [qKey, paginationModel, search],
    () => listModel(paginationModel, search),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  React.useEffect(() => {
    if (!listQuery.isPreviousData && listQuery.data?.meta.next) {
      queryClient.prefetchQuery({
        queryKey: [qKey, paginationModel],
        queryFn: async () => preListModel(paginationModel, search),
        staleTime: 0,
        cacheTime: 0,
      });
    }
  }, [queryClient, listQuery, paginationModel, search]);

  const newData = listQuery.data?.data ?? [];
  let columns = Object.entries(newData.length > 0 ? newData[0] : []).map(([k, v]) => {
    return { field: k.toLowerCase(), headerName: k, flex: 1 };
  });

  const selectAllButtonInHeader = {
    // ...GRID_CHECKBOX_SELECTION_COL_DEF,
    width: 60,
    field: "",
    headerName: "",
    flex: 0,
    sortable: false,
    disableColumnMenu: true,
    // disableColumnMenu: false,
    renderHeader: () => (
      <Box sx={{ display: "flex", gap: "5px" }}>
        <Button
          {...headerButtonProps}
          title="Add all to target devices"
          onClick={() => {
            setDevices((pre) => {
              return [...pre, ...newData.filter((v) => !pre.includes(v))];
            });
          }}
        >
          <AddIcon fontSize="small" />
        </Button>
      </Box>
    ),
    // renderCell: (params: GridCellParams) => (
    //   <Box
    //     onChange={(event) => {
    //       console.log('rrrreeee', event, params)
    //     }}
    //   />
    // ),
  };

  columns = [selectAllButtonInHeader, ...columns];

  const table = (
    <DataGrid
      sx={{
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
          display: "none",
        },
        width: "100%",
      }}
      columnVisibilityModel={{
        id: false,
        name: false,
        city: false,
      }}
      rows={newData}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 8 },
        },
      }}
      paginationMode="server"
      onPaginationModelChange={setPaginationModel}
      rowCount={listQuery.data?.meta.count ?? 0}
      paginationModel={paginationModel}
      onRowSelectionModelChange={(ids) =>
        setDevices((pre) => {
          const data = ids
            .map((v) => newData.filter((vv) => vv.id === v))
            .flat()
            .filter((v) => !pre.includes(v));
          return [...pre, ...data];
        })
      }
    />
  );

  return (
    <Box sx={{ display: "flex", flexFlow: "column", width: "100%", gap: 0.5, height: "450px" }}>
      <Box sx={{ display: "flex", flexFlow: "row", gap: 1 }}>
        <TextField
          sx={{ width: "100%" }}
          label="Search in Devices (every column)"
          variant="outlined"
          size="small"
          onChange={onNameChange}
        />
        <Tooltip title="Export Data">
          <Button
            onClick={async () => {
              const csvFile = await exportMutation.mutateAsync(search);
              const csvURL = URL.createObjectURL(csvFile);
              const tempLink = document.createElement("a");
              tempLink.href = csvURL;
              tempLink.setAttribute("download", `devices.${new Date().getTime().toString()}.csv`);
              tempLink.click();
            }}
            style={{ textTransform: "none" }}
            variant="contained"
          >
            {" "}
            <FileDownloadIcon fontSize="small" />{" "}
          </Button>
        </Tooltip>
      </Box>
      {columns.length > 0 && table}
    </Box>
  );
};

export default DeviceTable;
