import React from "react";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import omitBy from "lodash/omitBy";
import { useSearchParams } from "react-router-dom";
import { DeviceFilters } from "pages/devices/main/DeviceFiltersDrawer";

const DEFAULT_FILTERS: DeviceFilters = {
  imei__icontains: "",
  mac__icontains: "",
  smapp_id__icontains: "",
  imsi__icontains: "",
  iccid__icontains: "",
  type__in: [],
  country__icontains: "",
  comment__icontains: "",
  ordering: "smapp_id",
};

type UseDeviceFilters = () => {
  filters: DeviceFilters;
  defaultFilters: DeviceFilters;
  nonEmptyFilters: DeviceFilters;
  setFilters: (filters: DeviceFilters) => void;
  resetFilters: () => void;
};

const useDeviceFilters: UseDeviceFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { filters, nonEmptyFilters } = React.useMemo(() => {
    const filters = { ...DEFAULT_FILTERS, ...Object.fromEntries(searchParams) };
    return {
      filters,
      nonEmptyFilters: omitBy(filters, isEmpty),
    };
  }, [searchParams]);

  const setFilters = React.useCallback(
    (values: Omit<DeviceFilters, "active">) => {
      setSearchParams(omitBy(values, isEmpty));
    },
    [setSearchParams]
  );

  const resetFilters = React.useCallback(() => {
    setFilters(DEFAULT_FILTERS);
  }, [setFilters]);

  React.useEffect(() => {
    const initial = { ...DEFAULT_FILTERS, ...filters };
    if (!isEqual(initial, Object.fromEntries(searchParams))) {
      setFilters(initial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return React.useMemo(
    () => ({
      nonEmptyFilters,
      filters,
      setFilters,
      resetFilters,
      defaultFilters: DEFAULT_FILTERS,
    }),
    [filters, nonEmptyFilters, resetFilters, setFilters]
  );
};

export default useDeviceFilters;
