import React from "react";
import { useQuery } from "@tanstack/react-query";
import { listAllSummationDetectionCounts } from "queries/devices/averageDetectionCounts";
import { formatDate } from "utils/date-fns";

export type UseSumDetectionCountDeltasOptions = {
  from: Date;
  to: Date;
  frequency: string;
  deviceIdIn?: string[];
  enabled?: boolean;
};
const useSumDetectionCountDeltas = ({
  from,
  to,
  deviceIdIn,
  enabled,
  frequency,
}: UseSumDetectionCountDeltasOptions) => {
  const params = React.useMemo(() => {
    from.setHours(0, 0, 0, 0);
    to.setHours(23, 59, 0, 0);
    // this will add plus one day to 'to' parameter
    // without it, avgApi doesn't include today in result
    // to.setDate(to.getDate() + 1);

    return {
      created_at__gte: from.getTime() / 1000,
      created_at__lte: to.getTime() / 1000,
      device__id__in: deviceIdIn,
      ordering: "timestamp",
      frequency,
    };
  }, [deviceIdIn, from, to, frequency]);

  const summationDetectionCountQuery = useQuery({
    queryKey: ["list-all-summation-detection-counts", params],
    queryFn: async () => (await listAllSummationDetectionCounts(params)).data,
    enabled,
  });

  const data = React.useMemo(
    () =>
      Object.fromEntries(
        (summationDetectionCountQuery.data ?? []).map(
          ({ timestamp, sum_detection_count_delta }) => [
            formatDate(new Date(timestamp * 1000), true),
            { detectionCountDelta: sum_detection_count_delta },
          ]
        )
      ),
    [summationDetectionCountQuery.data]
  );

  return {
    data,
    error: summationDetectionCountQuery.error,
    isLoading: summationDetectionCountQuery.isLoading,
  };
};

export default useSumDetectionCountDeltas;
