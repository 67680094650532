import CONFIG from "config";
import Cookies from "js-cookie";
import { Buffer } from "buffer";

type GetAccessToken = () => string | undefined;
export const getAccessToken: GetAccessToken = () => Cookies.get("access");

type GetRefreshToken = () => string | undefined;
export const getRefreshToken: GetRefreshToken = () => Cookies.get("refresh");

export type GetTokens = () => {
  access: ReturnType<GetAccessToken>;
  refresh: ReturnType<GetRefreshToken>;
};
export const getTokens: GetTokens = () => ({
  access: getAccessToken(),
  refresh: getRefreshToken(),
});

type SetAccessToken = (token: string) => void;
export const setAccessToken: SetAccessToken = (token) =>
  Cookies.set("access", token, {
    expires: new Date(new Date().getTime() + CONFIG.auth.tokens.access.expires),
  });

type SetRefreshToken = (token: string) => void;
export const setRefreshToken: SetRefreshToken = (token) =>
  Cookies.set("refresh", token, {
    expires: new Date(new Date().getTime() + CONFIG.auth.tokens.refresh.expires),
  });

type SetTokensProps = { access?: string; refresh?: string };
type SetTokens = (params: SetTokensProps) => void;
export const setTokens: SetTokens = ({ access, refresh }) => {
  if (access) setAccessToken(access);
  if (refresh) setRefreshToken(refresh);
};

type RemoveAccessToken = () => void;
export const removeAccessToken: RemoveAccessToken = () => Cookies.remove("access");

type RemoveRefreshToken = () => void;
export const removeRefreshToken: RemoveRefreshToken = () => Cookies.remove("refresh");

type RemoveTokens = () => void;
export const removeTokens: RemoveTokens = () => {
  removeAccessToken();
  removeRefreshToken();
};

type Payload = {
  user_id: string;
  user: {
    name: string;
    type: "admin" | "corporate" | "regular";
  };
};

export type DecodedToken<THeader = unknown, TPayload extends Payload = Payload> = {
  raw: string;
  header?: THeader;
  payload?: TPayload;
};

type Decode = (rawToken: string) => DecodedToken;
export const decode: Decode = (rawToken) => {
  let header;
  try {
    header = JSON.parse(Buffer.from(rawToken.split(".")[0], "base64").toString());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Failed to decode header of token, message: ${e}`);
  }

  let payload;
  try {
    payload = JSON.parse(Buffer.from(rawToken.split(".")[1], "base64").toString());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Failed to decode payload of token, message: ${e}`);
  }

  return {
    raw: rawToken,
    header,
    payload,
  };
};
