import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getAccessToken } from "utils/token";

const ProtectedRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  const accessToken = getAccessToken();
  if (accessToken) {
    return <>{children}</>;
  }
  return <Navigate to="/login" replace state={{ from: location.pathname }} />;
};

export default ProtectedRoute;
