import useAvgDetectionCountDeltas, {
  UseAvgDetectionCountDeltasOptions,
} from "hooks/useAvgDetectionCountDeltas";
import useSumDetectionCountDeltas from "hooks/useSumDetectionCountDeltas";
import React from "react";
import ChartWithRQ from "./ChartWithRQ";
import { ChartProps } from "./Chart";

export const DC_CHART_CONFIG = {
  dataKey: "detectionCountDelta",
  stroke: "#f4b519",
  connectNulls: true,
  strokeWidth: 3,
  fill: "#f4b51999",
} as const;

const DC_CHART_CONFIG_ARR = [DC_CHART_CONFIG];

export type DetectionCountDeltaChartProps = UseAvgDetectionCountDeltasOptions &
  Pick<ChartProps<"detectionCountDelta">, "chartConfig">;

export const DetectionCountDeltaSummedChart: React.FC<
  DetectionCountDeltaChartProps & { frequency: string }
> = ({ chartConfig = DC_CHART_CONFIG_ARR, ...props }) => {
  const { data, error } = useSumDetectionCountDeltas(props);
  return <ChartWithRQ data={data} error={error} chartConfig={chartConfig} />;
};

const DetectionCountDeltaChart: React.FC<DetectionCountDeltaChartProps> = ({
  chartConfig = DC_CHART_CONFIG_ARR,
  ...props
}) => {
  const { data, error } = useAvgDetectionCountDeltas(props);
  return <ChartWithRQ data={data} error={error} chartConfig={chartConfig} />;
};
export default DetectionCountDeltaChart;
