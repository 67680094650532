import axios from "axios";
import { ListResponse } from "queries/types";
import { listAllFactory } from "utils/query";

type OrderingParams =
  | "timestamp"
  | "-timestamp"
  | "avg_detection_count"
  | "-avg_detection_count"
  | "avg_detection_count_delta"
  | "-avg_detection_count_delta";

type ListAverageDetectionCountParams = {
  page: number;
  page_size: number;
  id__in?: string[];
  device__id?: string;
  device__id__in?: string[];
  frequency?: string;
  ordering?: OrderingParams | OrderingParams[];
};

export type ListAverageDetectionCountItem = {
  timestamp: number;
  avg_detection_count: number | null;
  avg_detection_count_delta: number | null;
};

export const listAverageDetectionCounts = async (
  params?: ListAverageDetectionCountParams
): Promise<ListResponse<ListAverageDetectionCountItem>> => {
  const { data } = await axios.get<ListResponse<ListAverageDetectionCountItem>>(
    "/api/devices/average-detection-counts/",
    { params }
  );
  return data;
};

export type ListSummationDetectionCountItem = {
  id: string;
  timestamp: number;
  sum_detection_count: number | null;
  sum_detection_count_delta: number | null;
};

export const listSummationDetectionCounts = async (
  params?: ListAverageDetectionCountParams
): Promise<ListResponse<ListSummationDetectionCountItem>> => {
  const { data } = await axios.get<ListResponse<ListSummationDetectionCountItem>>(
    "/api/devices/summation-detection-counts/",
    { params }
  );
  return data;
};

export const listAllAverageDetectionCounts = listAllFactory(listAverageDetectionCounts);
export const listAllSummationDetectionCounts = listAllFactory(listSummationDetectionCounts);
