import axios from "axios";
import { USER_TYPE } from "queries/users";
import { ListResponse } from "../types";

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum DEVICE_TYPE {
  "Premium" = "premium",
  "Mini" = "mini",
}

export const DEVICE_ORDERING_FIELDS = [
  {
    value: "type",
    label: "Type",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "name",
    label: "Name",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "smapp_id",
    label: "Smapp ID",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "imei",
    label: "IMEI",
    userType: [USER_TYPE.ADMIN],
  },
  {
    value: "mac",
    label: "MAC",
    userType: [USER_TYPE.ADMIN],
  },
  {
    value: "imei",
    label: "Unique ID",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE],
  },
  {
    value: "imsi",
    label: "IMSI",
    userType: [USER_TYPE.ADMIN],
  },
  {
    value: "iccid",
    label: "ICCID",
    userType: [USER_TYPE.ADMIN],
  },
  {
    value: "country",
    label: "Country",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "city",
    label: "City",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "timezone",
    label: "Timezone",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "created_at",
    label: "Created at",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "updated_at",
    label: "Updated at",
    userType: [USER_TYPE.REGULAR, USER_TYPE.CORPORATE, USER_TYPE.ADMIN],
  },
  {
    value: "comment",
    label: "Comment",
    userType: [USER_TYPE.ADMIN],
  },
] as const;

export type ListDeviceParams = {
  search?: string;
  hr?: any;
  page: number;
  page_size: number;
  paginator?: string;
  active?: boolean;
  id__in?: string[];
  id__icontains?: string;
  type?: DEVICE_TYPE;
  type__in?: DEVICE_TYPE[];
  smapp_id__icontains?: string;
  smapp_id__in?: string[];
  imei__icontains?: string;
  mac__icontains?: string;
  imsi__icontains?: string;
  iccid__icontains?: string;
  comment__icontains?: string;
  country__icontains?: string;
  ordering?: (typeof DEVICE_ORDERING_FIELDS)[number]["value"];
};
export type ListDeviceItem = {
  id: string;
  current_crop: null | string;
  current_pest: null | string;
  last_measurement: null | string;
  last_geo_point: null | string;
  last_image: null | string;
  current_commission_status: null | string;
  current_config: null | string;
  current_firmware: null | string;
  last_network_diagnostic: null | string;
  active_operation_requests: string[];
  created_at: number;
  most_relevant_geo_point: {
    longitude: number;
    latitude: number;
  } | null;
  updated_at: number;
  corporations: string[] | null;
  comment: null | string;
  type: DEVICE_TYPE;
  name: null | string;
  sid: number;
  smapp_id: string;
  imei: string;
  mac: string;
  imsi: string;
  iccid: string;
  country: string | null;
  city: string | null;
  timezone: string;
  target_config: string;
  // target_config: null | string;
  target_firmware: string;
  last_summed_detection_count: number;
  last_summed_detection_count_delta: number;
};
export const listDevices = async (
  params?: ListDeviceParams
): Promise<ListResponse<ListDeviceItem>> => {
  const { data } = await axios.get<ListResponse<ListDeviceItem>>("/api/devices/", { params });
  return data;
};

export const listDevicesV2 = async (params?: ListDeviceParams): Promise<ListDeviceItem[]> => {
  const { data } = await axios.get<ListDeviceItem[]>("/api/devices/", { params });
  return data;
};

// TODO: check this
export type DetailDeviceItem = ListDeviceItem;
type DetailDeviceParams = {
  id: string;
};
export const detailDevice = async ({ id }: DetailDeviceParams): Promise<DetailDeviceItem> => {
  const { data } = await axios.get<DetailDeviceItem>(`/api/devices/${id}`);
  return data;
};

export type UpdateDevicePartialParams = Partial<
  Pick<ListDeviceItem, "name" | "comment" | "country" | "city" | "timezone"> & {
    target_crop: string | null;
    target_pest: string | null;
    target_config: string | null;
    target_operation_request: string | null;
    target_firmware: string | null;
  }
>;

export type UpdateDeviceParams = {
  id: string;
  params: UpdateDevicePartialParams;
};
export const updateDevice = async ({
  id,
  params,
}: UpdateDeviceParams): Promise<DetailDeviceItem> => {
  const { data } = await axios.patch<DetailDeviceItem>(`/api/devices/${id}/`, params);
  return data;
};
