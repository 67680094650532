const appEnv = process.env.REACT_APP_FE_ENV === "prod" ? "prod" : "dev";
const CONFIG = Object.freeze({
  baseUrl: process.env.REACT_APP_BASE_URL || "//localhost",
  appVersion: process.env.REACT_APP_GIT_VERSION || "0.1.2.3.4",
  locale: document.documentElement.lang || navigator.language || "en-US",
  auth: {
    tokens: {
      access: {
        expires: 5 * 60 * 60 * 1000, // 5 hours
      },
      refresh: {
        expires: 24 * 60 * 60 * 1000, // 24 hours
      },
    },
  },
  api: {
    maxPageSize: 50,
    maxHugePageSize: 500,
  },
  logLevel: appEnv === "prod" ? "off" : "log",
});

export default CONFIG;
