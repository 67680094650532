// import { useAuth } from "context/AuthProvider";
import React from "react";
import { Navigate } from "react-router-dom";
import ROUTES from "routes";
import { getAccessToken } from "utils/token";

const AnonymRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
  // const { currentUser } = useAuth();

  // const access = getAccessToken()

  if (getAccessToken()) {
    return <Navigate to={ROUTES.HOME} replace />;
  }

  return <>{children}</>;
};

export default AnonymRoute;
