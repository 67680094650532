import React from "react";
import AppWrapper from "components/AppWrapper";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { Box, Checkbox, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import { useAuth } from "context/AuthProvider";
import { listDevices } from "queries/devices";
import { FlexLoader, Loader } from "components/Loader";
import { debounce, flatten } from "lodash";
import { listAllMeasurements } from "queries/devices/measurements";
import { listAllPests } from "queries/pests";
import { listAllSummationDetectionCounts } from "queries/devices/averageDetectionCounts";
import useDateRangeForSummedDetectionCount from "hooks/useDateRangeForSummedDetectionCount";
import DeviceCard, { DeviceCardProps } from "components/DeviceCard";
import { resolveKey, fetchResourceByIds, customFetchResource } from "utils/query";
import { listAllCrops } from "queries/crops";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useDeviceFilters from "hooks/useDeviceFilters";
import { listAllNetworkDiagnostics } from "queries/devices/networkDiagnostics";
import { listAllCommissionStatuses } from "queries/devices/commissionStatus";
import { listAllFirmwares } from "queries/firmwares";
import { listAllConfigs } from "queries/configs";
import { detailCorporation } from "queries/users";
import { listAllGeolocations } from "queries/devices/geolocations";
import DeviceFiltersDrawer from "./DeviceFiltersDrawer";
import DeviceFiltersFab from "./DeviceFiltersFab";

const INFINITE_PAGE_SIZE = 20;

const DeviceMain: React.FC = () => {
  const { isAdmin } = useAuth();
  const { nonEmptyFilters } = useDeviceFilters();

  const [search, setSearch] = React.useState("");
  const [archived, setArchived] = React.useState(true);
  const onNameChange = React.useMemo(
    () =>
      debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(value);
      }, 600),
    []
  );

  React.useEffect(() => {
    return () => {
      onNameChange.cancel();
    };
  }, [onNameChange]);

  const { data: detailCorpQuery } = useQuery({
    queryFn: () => detailCorporation({ name__icontains: search }),
    queryKey: ["detail-corps", { search }],
    enabled: search.length > 0,
  });

  const detailCorpList = (detailCorpQuery?.data ?? []).map((v) => v.devices).flat();
  const paramsForDaySummedDc = useDateRangeForSummedDetectionCount(0);
  const paramsForWeekSummedDc = useDateRangeForSummedDetectionCount(7);

  const {
    data: infData,
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["inf-list-devices", nonEmptyFilters, detailCorpList, archived],
    queryFn: async ({ pageParam = 1 }) => {
      const listDeviceParams = {
        page: pageParam,
        page_size: INFINITE_PAGE_SIZE,
        ...nonEmptyFilters,
      };
      if (detailCorpList.length > 0) {
        listDeviceParams.id__in = detailCorpList;
      }
      if (archived) {
        listDeviceParams.active = archived;
      }
      const { data: devices, meta } = await listDevices(listDeviceParams);

      const [
        measurementsById,
        pestsById,
        cropsById,
        firmwaresById,
        configsById,
        commissionStatusById,
        networkDiagnosticsById,
        geolocationsById,
      ] = await Promise.all([
        fetchResourceByIds(
          devices.map((it) => it.last_measurement),
          listAllMeasurements
        ),
        fetchResourceByIds(
          devices.map((it) => it.current_pest),
          listAllPests
        ),
        fetchResourceByIds(
          devices.map((it) => it.current_crop),
          listAllCrops
        ),
        fetchResourceByIds(
          devices.map((it) => it.current_firmware),
          listAllFirmwares
        ),
        fetchResourceByIds(
          devices.map((it) => it.current_config),
          listAllConfigs
        ),
        fetchResourceByIds(
          devices.map((it) => it.current_commission_status),
          listAllCommissionStatuses
        ),
        fetchResourceByIds(
          devices.map((it) => it.last_network_diagnostic),
          listAllNetworkDiagnostics
        ),
        fetchResourceByIds(
          devices.map((it) => it.last_geo_point),
          listAllGeolocations
        ),
      ]);

      const data: DeviceCardProps[] = devices.map((it) => {
        return {
          ...it,
          current_pest: resolveKey(it.current_pest, pestsById),
          current_crop: resolveKey(it.current_crop, cropsById),
          last_measurement: resolveKey(it.last_measurement, measurementsById),
          last_network_diagnostic: resolveKey(it.last_network_diagnostic, networkDiagnosticsById),
          current_commission_status: resolveKey(it.current_commission_status, commissionStatusById),
          current_firmware: resolveKey(it.current_firmware, firmwaresById),
          current_config: resolveKey(it.current_config, configsById),
          last_geo_point: resolveKey(it.last_geo_point, geolocationsById),
          summed_day_detectioncounts: {
            promise:
              listAllSummationDetectionCounts({
                ...paramsForDaySummedDc,
                device__id__in: it.id,
                frequency: "day",
              }),
            key: `day-${it.id}`
          },
          summed_week_detectioncounts: {
            promise:
              listAllSummationDetectionCounts({
                ...paramsForWeekSummedDc,
                device__id__in: it.id,
                frequency: "week",
              }),
            key: `week-${it.id}`
          },
        };
      });

      return { data, meta };
    },
    getNextPageParam: (lastPage) => (lastPage.meta.next ? lastPage.meta.page + 1 : undefined),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    keepPreviousData: true,
  });

  const data = React.useMemo(
    () => flatten((infData?.pages ?? [])?.map((it) => it.data)),
    [infData]
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage: !!hasNextPage,
    onLoadMore: fetchNextPage,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 1000px 0px",
  });

  let content = null;
  if (isLoading) {
    content = <FlexLoader />;
  } else if (data) {
    content = (
      <>
        <Stack spacing={2}>
          {data.map((it) => (
            <DeviceCard key={it.id} {...it} ref={sentryRef} />
          ))}
        </Stack>
        {hasNextPage && isFetchingNextPage && (
          <Box sx={{ margin: "64px auto" }}>
            <Loader />
          </Box>
        )}
        {!hasNextPage && (
          <Box style={{ margin: "64px" }}>
            <Typography variant="body1" style={{ textAlign: "center" }}>
              No more items
            </Typography>
          </Box>
        )}
      </>
    );
  } else {
    content = String(error);
  }

  return (
    <AppWrapper>
      {isAdmin && (
        <Box sx={{ display: "flex", flexFlow: "row" }}>
          <TextField
            sx={{ m: 1.5, width: "66%" }}
            label="Search in corps"
            variant="standard"
            onChange={onNameChange}
          />
          <FormControlLabel
            sx={{ marginLeft: "auto" }}
            control={
              <Checkbox
                checked={archived}
                onChange={(e) => {
                  setArchived(e.target.checked);
                }}
              />
            }
            label="Hide archived devices"
          />
        </Box>
      )}
      {content}
      <DeviceFiltersFab />
      <DeviceFiltersDrawer />
    </AppWrapper>
  );
};

export default DeviceMain;
