import React, { useEffect, useMemo } from "react";
import { timeZones } from "utils/time";
import { DialogContent, Box, TextField, Autocomplete } from "@mui/material";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "context/AuthProvider";
import { updateDevice } from "queries/devices";
import UpdateDeviceGenericsDialogActions, {
  UpdateDeviceGenericsDialogActionsProps,
} from "./UpdateDeviceGenericsDialogActions";

export type UpdateDeviceGenericsFormValues = {
  name: string;
  comment?: string;
  country: string;
  city: string;
  timezone: string;
};

const schema = Yup.object({
  name: Yup.string(),
  comment: Yup.string(),
  country: Yup.string(),
  city: Yup.string(),
  timezone: Yup.string(),
});

type UpdateDeviceGenericsDialogFormProps = {
  initialValues: UpdateDeviceGenericsFormValues;
  id: string;
  handleSuccess: () => void;
  actionsProps: UpdateDeviceGenericsDialogActionsProps;
};
const UpdateDeviceGenericsDialogForm: React.FC<UpdateDeviceGenericsDialogFormProps> = ({
  id,
  initialValues,
  handleSuccess,
  actionsProps,
}) => {
  const { isAdmin } = useAuth();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<UpdateDeviceGenericsFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });
  const queryClient = useQueryClient();

  const onSuccess = React.useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["list-device"] });
    queryClient.invalidateQueries({ queryKey: ["list-all-device"] });
    queryClient.invalidateQueries({ queryKey: ["detail-device", { id }] });
    handleSuccess();
  }, [queryClient, id, handleSuccess]);

  const mutation = useMutation({
    mutationFn: updateDevice,
    onSuccess,
  });

  const onSubmit = React.useCallback(
    (params: UpdateDeviceGenericsFormValues) => {
      if (!id) throw new Error("No device id when submitting");

      mutation.mutate({ id, params });
    },
    [id, mutation]
  );

  const tz = useMemo(() => {
    const tz = timeZones.find((v) => v === getValues("timezone")) ?? "";
    return tz;
  }, [getValues]);

  return (
    <Box noValidate component="form" onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>
        <TextField
          label="Name"
          error={!!errors.name}
          helperText={errors.name?.message}
          placeholder="Enter some text"
          fullWidth
          size="small"
          margin="normal"
          required
          {...register("name")}
        />
        {isAdmin && (
          <TextField
            label="Comment"
            error={!!errors.comment}
            helperText={errors.comment?.message}
            placeholder="Enter some text"
            fullWidth
            size="small"
            margin="normal"
            multiline
            rows={4}
            {...register("comment")}
          />
        )}
        <TextField
          label="Country"
          error={!!errors.country}
          helperText={errors.country?.message}
          placeholder="Enter some text"
          fullWidth
          size="small"
          margin="normal"
          {...register("country")}
        />
        <TextField
          label="City"
          error={!!errors.city}
          helperText={errors.city?.message}
          placeholder="Enter some text"
          fullWidth
          size="small"
          margin="normal"
          {...register("city")}
        />
        <Autocomplete
          sx={{ flex: "2" }}
          ListboxProps={{
            style: { maxHeight: 200 },
          }}
          defaultValue={tz}
          options={timeZones}
          renderInput={(params) => (
            <TextField
              {...params}
              {...register("timezone")}
              size="small"
              label="Timezone"
              placeholder="Enter some text"
              variant="outlined"
            />
          )}
        />
      </DialogContent>
      <UpdateDeviceGenericsDialogActions isSaveLoading={mutation.isLoading} {...actionsProps} />
    </Box>
  );
};

export default UpdateDeviceGenericsDialogForm;
