import React from "react";
import useDateRange from "./useDateRange";

const useDateRangeForSummedDetectionCount = (numberOfDays: number) => {
  const { dateRange } = useDateRange({ defaultDuration: { days: numberOfDays } });

  const params = React.useMemo(() => {
    dateRange.from.setHours(0, 0, 0, 0);
    dateRange.to.setHours(23, 59, 59, 0);
    // dateRange.to.setHours(0, 0, 0, 0);
    // this will add plus one day to 'to' parameter
    // without it, avgApi doesn't include today in result
    // dateRange.to.setDate(dateRange.to.getDate() + 1);

    return {
      created_at__gte: dateRange.from.getTime() / 1000,
      created_at__lte: dateRange.to.getTime() / 1000,
      ordering: "timestamp",
    };
  }, [dateRange]);

  return params;
};

export default useDateRangeForSummedDetectionCount;
