import React from "react";
import { useQuery } from "@tanstack/react-query";
import { listAllAverageDetectionCounts } from "queries/devices/averageDetectionCounts";
import { formatDate } from "utils/date-fns";

export type UseAvgDetectionCountDeltasOptions = {
  from: Date;
  to: Date;
  deviceIdIn?: string[];
  enabled?: boolean;
};
const useAvgDetectionCountDeltas = ({
  from,
  to,
  deviceIdIn,
  enabled,
}: UseAvgDetectionCountDeltasOptions) => {
  const params = React.useMemo(() => {
    from.setHours(0, 0, 0, 0);
    to.setHours(0, 0, 0, 0);
    // this will add plus one day to 'to' parameter
    // without it, avgApi doesn't include today in result
    to.setDate(to.getDate() + 1);

    return {
      created_at__gte: from.getTime() / 1000,
      created_at__lte: to.getTime() / 1000,
      device__id__in: deviceIdIn,
      ordering: "timestamp",
    };
  }, [deviceIdIn, from, to]);

  const averageDetectionCountQuery = useQuery({
    queryKey: ["list-all-average-detection-counts", params],
    queryFn: async () => (await listAllAverageDetectionCounts(params)).data,
    enabled,
  });

  const data = React.useMemo(
    () =>
      Object.fromEntries(
        (averageDetectionCountQuery.data ?? []).map(({ timestamp, avg_detection_count_delta }) => [
          formatDate(new Date(timestamp * 1000), true),
          { detectionCountDelta: avg_detection_count_delta },
        ])
      ),
    [averageDetectionCountQuery.data]
  );

  return {
    data,
    error: averageDetectionCountQuery.error,
    isLoading: averageDetectionCountQuery.isLoading,
  };
};

export default useAvgDetectionCountDeltas;
