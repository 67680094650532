import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, TextField, Tooltip } from "@mui/material";
import _, { debounce } from "lodash";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ListUserItem, ListUserParams, exportUsers, listUsers } from "queries/users";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

type UserItemTypeExtended = ListUserItem & {
  name: string;
  joined: string;
};

export const qKey = "management-list-of-users";
const PAGE_SIZE = 5;

const modelParams = (paginationModel: any, search: string) => {
  const params = {
    page: paginationModel.page + 1,
    page_size: paginationModel.pageSize,
    ordering: "-date_joined" as const,
    hr: "t",
  } as ListUserParams;
  if (search.length > 0) params.search = search;
  return params;
};

const listModel = (paginationModel: any, search: string) => {
  return listUsers(modelParams(paginationModel, search));
};

const preListModel = (paginationModel: any, search: string) => {
  const params = modelParams(paginationModel, search);
  params.page += 1;
  return listUsers(params);
};

const UserTable = () => {
  const queryClient = useQueryClient();
  const [paginationModel, setPaginationModel] = React.useState({ page: 0, pageSize: PAGE_SIZE });
  const [search, setSearch] = React.useState("");
  const onNameChange = React.useMemo(
    () =>
      debounce(({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setPaginationModel((pre) => ({ ...pre, page: 0 }));
        setSearch(value);
      }, 600),
    []
  );

  const exportMutation = useMutation({
    mutationFn: exportUsers,
  });

  const listQuery = useQuery(
    [qKey, paginationModel, search],
    () => listModel(paginationModel, search),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  React.useEffect(() => {
    if (!listQuery.isPreviousData && listQuery.data?.meta.next) {
      queryClient.prefetchQuery({
        queryKey: [qKey, "preFetch"],
        queryFn: async () => preListModel(paginationModel, search),
        staleTime: 0,
        cacheTime: 0,
      });
    }
  }, [queryClient, listQuery, paginationModel, search]);

  const newData = (listQuery.data?.data ?? []) as UserItemTypeExtended[];

  const modifData = newData.map((val) => {
    val.name = `${val.first_name} ${val.last_name}`;
    if (_.isNumber(val.date_joined)) {
      val.joined = new Date((val.date_joined as number) * 1000).toLocaleString();
    }
    return val;
  });

  const columns = Object.entries(modifData.length > 0 ? modifData[0] : []).map(([k, v]) => {
    return { field: k.toLowerCase(), headerName: k, flex: 1 };
  });

  const table = (
    <DataGrid
      sx={{
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
          display: "none",
        },
        width: "100%",
      }}
      columnVisibilityModel={{
        id: false,
        last_login: false,
        date_joined: false,
        first_name: false,
        last_name: false,
      }}
      rows={modifData}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 8 },
        },
      }}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      checkboxSelection
      rowCount={listQuery.data?.meta.count ?? 0}
    />
  );

  return (
    <Box sx={{ display: "flex", flexFlow: "column", width: "100%", gap: 0.5, height: "450px" }}>
      <Box sx={{ display: "flex", flexFlow: "row", gap: 1 }}>
        <TextField
          sx={{ width: "100%" }}
          label="Search in Users (every column)"
          variant="outlined"
          size="small"
          onChange={onNameChange}
        />
        <Tooltip title="Export Data">
          <Button
            onClick={async () => {
              const csvFile = await exportMutation.mutateAsync(search);
              const csvURL = URL.createObjectURL(csvFile);
              const tempLink = document.createElement("a");
              tempLink.href = csvURL;
              tempLink.setAttribute("download", `users.${new Date().getTime().toString()}.csv`);
              tempLink.click();
            }}
            style={{ textTransform: "none" }}
            variant="contained"
          >
            {" "}
            <FileDownloadIcon fontSize="small" />{" "}
          </Button>
        </Tooltip>
      </Box>
      {columns.length > 0 && table}
    </Box>
  );
};

export default UserTable;
