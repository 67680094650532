/* eslint-disable react/no-unused-prop-types */
import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DetailDeviceItem } from "queries/devices";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { ListImageItem, listImages, updateImage } from "queries/devices/images";
import { UseQueryResult, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { MINUTE_1 } from "utils/time";
import QueryError from "components/QueryError";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import { format, offsetInSeconds } from "utils/date-fns";
import ValidationPanelProvider from "context/ValidationPanelProvider";
import { useAuth } from "context/AuthProvider";
import ProcessedImage from "./ProcessedImage";
import DeltaImage from "./DeltaImage";
import PreviousImage from "./PreviousImage";
import ValidatedImage from "./ValidatedImage";

type ValidationPanelProps = { device: DetailDeviceItem };

type StepperProps = {
  deviceId: string;
  imageQuery: UseQueryResult<ListImageItem[]>;
  setCurrentTimestamp: StateSetter<number | undefined>;
};
export const Stepper: React.FC<StepperProps> = ({
  deviceId,
  imageQuery: leftImageQuery,
  setCurrentTimestamp,
}) => {
  const currentTimestamp = leftImageQuery.data?.[0]?.created_at;
  const leftTimestamp = leftImageQuery.data?.[1]?.created_at;
  const leftTooltipTitle = leftTimestamp ? format(new Date(leftTimestamp * 1000)) : "";

  const rightParams = React.useMemo(
    () => ({
      device__id: deviceId,
      page: 1,
      page_size: 2,
      ordering: "created_at" as const,
      created_at__gt: currentTimestamp,
    }),
    [deviceId, currentTimestamp]
  );

  const rightImageQuery = useQuery({
    queryKey: ["list-images", rightParams],
    queryFn: async () => (await listImages(rightParams)).data,
    staleTime: MINUTE_1,
  });

  const rightTimestamp = rightImageQuery.data?.[0]?.created_at;
  const rightTooltipTitle = rightTimestamp ? format(new Date(rightTimestamp * 1000)) : "";

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <IconButton
        type="button"
        disabled={!leftTimestamp}
        onClick={(e) => {
          e.stopPropagation();
          setCurrentTimestamp(leftTimestamp);
        }}
      >
        <Tooltip title={leftTooltipTitle} arrow>
          <KeyboardArrowLeftOutlinedIcon />
        </Tooltip>
      </IconButton>
      <IconButton
        type="button"
        disabled={!rightTimestamp}
        onClick={(e) => {
          e.stopPropagation();
          setCurrentTimestamp(rightTimestamp);
        }}
      >
        <Tooltip title={rightTooltipTitle} arrow>
          <KeyboardArrowRightOutlinedIcon />
        </Tooltip>
      </IconButton>
    </Box>
  );
};
type StepperInfoProps = {
  deviceTz?: number;
  imageQuery: UseQueryResult<ListImageItem[]>;
};
export const StepperInfo: React.FC<StepperInfoProps> = ({ imageQuery, deviceTz }) => {
  const isImgDataReady = imageQuery.data && imageQuery.data.length > 0;
  let deviceLocalTime = "";
  if (isImgDataReady && deviceTz) {
    deviceLocalTime = `Device localtime: ${format(
      new Date((imageQuery.data[0].created_at + (deviceTz || 0)) * 1000)
    )}`;
  }

  if (imageQuery.isLoading || isImgDataReady) {
    const content = imageQuery.isLoading ? (
      <Skeleton variant="text" width={200} sx={{ display: "inline-block" }} />
    ) : (
      format(new Date(imageQuery.data[0].created_at * 1000))
    );
    return (
      <Tooltip title={deviceLocalTime}>
        <span>
          <Typography variant="overline">Current timestamp: {content} </Typography>
        </span>
      </Tooltip>
    );
  }
  return null;
};

const ValidationPanel: React.FC<ValidationPanelProps> = ({ device }) => {
  const { isAdmin } = useAuth();
  const [currentTimestamp, setCurrentTimestamp] = React.useState<undefined | number>(undefined);
  const params = React.useMemo(
    () => ({
      device__id: device.id,
      page: 1,
      page_size: 2,
      ordering: "-created_at" as const,
      created_at__lte: currentTimestamp,
    }),
    [currentTimestamp, device.id]
  );

  const [isOpen, setIsOpen] = React.useState(true);

  const imageQuery = useQuery({
    queryKey: ["list-images", params],
    queryFn: async () => (await listImages(params)).data,
    staleTime: MINUTE_1,
    enabled: isOpen,
  });

  const data = imageQuery.data?.[0];

  const queryClient = useQueryClient();
  const patchMutation = useMutation({
    mutationFn: async () => {
      await updateImage({ id: data?.id || "", body: { is_validated: false } });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["download-image", { id: data?.id || "" }] });
      imageQuery.refetch();
    },
  });

  let content = null;
  if (imageQuery.error) {
    content = <QueryError error={imageQuery.error} />;
  } else {
    content = (
      <>
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <PreviousImage imageQuery={imageQuery} device={device} />
          <DeltaImage isLoading={imageQuery.isLoading} data={data} />
        </Grid>
        <Grid container spacing={3}>
          <ProcessedImage isLoading={imageQuery.isLoading} data={data} />
          <ValidatedImage imageQuery={imageQuery} />
        </Grid>
      </>
    );
  }

  return (
    <ValidationPanelProvider mutationSettledCallback={() => imageQuery.refetch()}>
      <Accordion
        expanded={isOpen}
        onChange={() => {
          setIsOpen(!isOpen);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreOutlinedIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{ alignItems: "center" }}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Typography variant="h6">Validation form </Typography>
            <StepperInfo deviceTz={offsetInSeconds(device.timezone)} imageQuery={imageQuery} />
            {isAdmin ? (
              <Tooltip arrow title="Invalidate image">
                <Checkbox
                  color="success"
                  checked={data?.is_validated || false}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!data?.is_validated) return;
                    patchMutation.mutate();
                  }}
                />
              </Tooltip>
            ) : null}
            <Stepper
              imageQuery={imageQuery}
              deviceId={device.id}
              setCurrentTimestamp={setCurrentTimestamp}
            />
          </Stack>
        </AccordionSummary>
        <AccordionDetails>{content}</AccordionDetails>
      </Accordion>
    </ValidationPanelProvider>
  );
};

export default ValidationPanel;
