import React from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Box, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useDrawer } from "context/DrawerProvider";
// import { useNav } from "context/NavProvider";
import { ReactComponent as LogoHBlack } from "icons/logo-h-black.svg";

const AppBar: React.FC = () => {
  const {
    setLeftDrawer,
    //  setRightDrawer
  } = useDrawer();
  return (
    <MuiAppBar position="fixed" color="inherit">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setLeftDrawer("nav")}
          edge="start"
          sx={{
            marginRight: 2,
            // ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flex: 1, display: "flex" }}>
          <Box sx={{ flex: 1, maxWidth: "10rem", display: "flex" }}>
            <LogoHBlack />
          </Box>
          {/* <Typography variant="h6" noWrap component="div">
            scoutlabs
          </Typography> */}
        </Box>
        {/* <IconButton
          color="primary"
          aria-label="open preferences"
          component="label"
          onClick={() => {
            setRightDrawer("preferences");
          }}
        >
          <SettingsOutlinedIcon />
        </IconButton> */}
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
